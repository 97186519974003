import React from 'react'
import Block from '../block';
import Slider from 'react-slick';
import { RichText } from '../richTextOptions';
import $ from 'jquery'

const VetsSlider = ({ slider, settings, className }) => {
    return (
        <div className={className}>
            {slider.headerDescription && <RichText data={slider.headerDescription} />}
            <Slider {...settings} className="tys">
                {slider.sliderItems && (
                    slider.sliderItems.map((block, i) => {
                        return (<Block key={i} block={block}></Block>)
                    })
                )}
            </Slider>
        </div>
    )
}

const VetsSliderDefault = ({ slider, settings, className }) => {
    return (
        <div className={className}>
            {slider.headerDescription && <RichText data={slider.headerDescription} />}
            <Slider {...settings} className="tys">
                {slider.sliderItems && (
                    slider.sliderItems.map((block, i) => {
                        return (<Block key={i} block={block}></Block>)
                    })
                )}
            </Slider>
        </div>
    )
}

const TestimonialsQuote = ({ slider, settings, className }) => {

    return (
        <div className={className}>
            <Slider {...settings} className="tys">
                {slider.sliderItems && (
                    slider.sliderItems.map((block, i) => {
                        return (<Block key={i} block={block}></Block>)
                    })
                )}
            </Slider>
        </div>
    )
}

const ContentfulBlockVetsSlider = (slider) => {
    var settings = {};
    var content = '';
    switch (slider.layout) {
        case 'Icon with text':
            settings = {
                loop: true,
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 600,
                dots: true,
                arrows: true,
                infinite: true,
                slidesToShow: 7,
                slidesToScroll: 1,
                autoplayHoverPause: true,
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 5,
                        }
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 4,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 320,
                        settings: {
                            slidesToShow: 2,
                        }
                    }
                ],
                beforeChange: (current, next) => {
                    $('.vets-slider .slick-slide a').attr('tabindex', -1)
                },
                afterChange: (current, next) => {
                    $('.vets-slider .slick-slide.slick-current a').attr('tabindex', 0)
                }
            };
            content = <VetsSlider slider={slider} settings={settings} className={'vets-slider'}></VetsSlider>
            break;
        case 'Quote':
            settings = {
                loop: true,
                // autoplay: true,
                // autoplaySpeed: 5000,
                speed: 600,
                dots: true,
                arrows: false,
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplayHoverPause: true,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2.5,
                            // swipeToSlide: true,
                            infinite: false,
                        }
                    },
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 1.5,
                            // swipeToSlide: true,
                            infinite: false,
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1.5,
                            // swipeToSlide: true,
                            infinite: false,
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1.1,
                            // swipeToSlide: true,
                            infinite: false,
                        }
                    },
                ],
                beforeChange: (current, next) => {
                    $('.quote-testimonials .slick-slide a').attr('tabindex', -1)
                },
                afterChange: (current, next) => {
                    $('.quote-testimonials .slick-slide.slick-current a').attr('tabindex', 0)
                }
            };
            content = <TestimonialsQuote slider={slider} settings={settings} className={'quote-testimonials'}></TestimonialsQuote>
            break;
        case 'Customer Reviews':
            settings = {
                loop: true,
                // autoplay: true,
                // autoplaySpeed: 5000,
                speed: 600,
                dots: true,
                arrows: true,
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplayHoverPause: true,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            arrows: false,
                            variableWidth: true
                        }
                    },
                ],
                beforeChange: (current, next) => {
                    $('.customer-reviews-slider .slick-slide a').attr('tabindex', -1)
                },
                afterChange: (current, next) => {
                    $('.customer-reviews-slider .slick-slide.slick-current a').attr('tabindex', 0)
                }
            };
            content = <VetsSlider slider={slider} settings={settings} className='customer-reviews-slider'></VetsSlider>
            break;
        case 'Customer Reviews Landing':
            settings = {
                loop: true,
                // autoplay: true,
                // autoplaySpeed: 5000,
                speed: 600,
                dots: true,
                arrows: true,
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplayHoverPause: true,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            arrows: false,
                            centerMode: true,
                            variableWidth: true,
                        }
                    },
                ],
                beforeChange: (current, next) => {
                    $('.customer-reviews-slider .slick-slide a').attr('tabindex', -1)
                },
                afterChange: (current, next) => {
                    $('.customer-reviews-slider .slick-slide.slick-current a').attr('tabindex', 0)
                }
            };
            content = <VetsSlider slider={slider} settings={settings} className='customer-reviews-slider'></VetsSlider>
            break;
        case 'Customer Reviews Landing':
            settings = {
                loop: true,
                // autoplay: true,
                // autoplaySpeed: 5000,
                speed: 600,
                dots: true,
                arrows: true,
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplayHoverPause: true,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            arrows: false,
                            centerMode: true,
                            variableWidth: true,
                        }
                    },
                ],
                beforeChange: (current, next) => {
                    $('.customer-reviews-slider .slick-slide a').attr('tabindex', -1)
                },
                afterChange: (current, next) => {
                    $('.customer-reviews-slider .slick-slide.slick-current a').attr('tabindex', 0)
                }
            };
            content = <VetsSlider slider={slider} settings={settings} className='customer-reviews-slider'></VetsSlider>
            break;
        case 'Customer Reviews Benefit Brokers':
            settings = {
                loop: true,
                // autoplay: true,
                // autoplaySpeed: 5000,
                speed: 600,
                arrows: true,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                autoplayHoverPause: true,
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                            variableWidth: true,
                        }
                    },
                    {
                        breakpoint: 375,
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                            variableWidth: true,
                        }
                    },
                ],
                beforeChange: (current, next) => {
                    $('.customer-reviews-slider .slick-slide a').attr('tabindex', -1)
                },
                afterChange: (current, next) => {
                    $('.customer-reviews-slider .slick-slide.slick-current a').attr('tabindex', 0)
                }
            };
            content = <VetsSlider slider={slider} settings={settings} className='customer-reviews-slider'></VetsSlider>
            break;
        case 'Additional Plans ConditionLP':
            settings = {
                loop: true,
                // autoplay: true,
                // autoplaySpeed: 5000,
                speed: 600,
                dots: true,
                arrows: false,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                autoplayHoverPause: true,
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                            variableWidth: true,
                        }
                    },
                    {
                        breakpoint: 375,
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                            variableWidth: true,
                        }
                    },
                ],
                beforeChange: (current, next) => {
                    $('.additional-plans-slider .slick-slide a').attr('tabindex', -1)
                },
                afterChange: (current, next) => {
                    $('.additional-plans-slider .slick-slide.slick-current a').attr('tabindex', 0)
                }
            };
            content = <VetsSlider slider={slider} settings={settings} className='additional-plans-slider'></VetsSlider>
            break;
        default:
            settings = {
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 600,
                dots: true,
                arrows: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                beforeChange: (current, next) => {
                    $('.vets-slider .slick-slide a').attr('tabindex', -1)
                },
                afterChange: (current, next) => {
                    $('.vets-slider .slick-slide.slick-current a').attr('tabindex', 0)
                }
            };
            content = <VetsSliderDefault slider={slider} settings={settings}></VetsSliderDefault>
            break;
    }

    return (
        <>{content}</>
    )
}

export default ContentfulBlockVetsSlider;
